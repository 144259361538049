@import '@react/common.scss';

.cmp-filterable-list__list-events-link {
  @include bold-md;

  position: absolute;
  top: var(--spacing-xxl);
  color: var(--color-primary-500);
  display: flex;
  align-items: center;
  width: 25%;

  @media #{$mq-mobile} {
    top: var(--spacing-md);
    width: auto;
  }

  &:hover {
    color: var(--color-primary-700);

    .icon-keyboard_arrow_right {
      @include link-icon-right;
    }

    .icon-keyboard_arrow_left {
      @include link-icon-left;
    }
  }

  &:active {
    color: var(--color-primary-800);
  }

  &--upcoming-event {
    right: 0;
  }

  &--past-event {
    left: 0;
  }

  .cmp-button__icon {
    @include transition-transform;

    &::before {
      @include bold-md;
    }
  }
}
