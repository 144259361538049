@import '@site/styles/foundations/typography.scss';

.cmp-filterable-list__table {
  display: table;
  border: none;

  &--hidden-row {
    display: none;
  }

  &-th {
    background-color: var(--color-gray-50);
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
    padding: var(--spacing-sm) var(--spacing-lg);
    vertical-align: middle;
    border-right: var(--spacing-xxxs) solid var(--color-white);
    position: relative;

    &:first-child {
      border-left: var(--spacing-xxxs) solid var(--color-gray-50);
    }

    &:last-child {
      border-right: var(--spacing-xxxs) solid var(--color-gray-50);
    }

    @media (--breakpoint-down-md) {
      padding: var(--spacing-sm) var(--spacing-sm);
      position: relative;
    }
  }

  &-wrapper {
    overflow-x: auto;
    padding-right: var(--spacing-xxxs);
  }

  &-tbody {
    a {
      width: 100%;
      display: block;
    }

    .cmp-filterable-list__table-row {
      &--selectable:hover {
        outline: var(--color-primary-500) solid var(--spacing-xxxs);
        cursor: pointer;
        outline-offset: calc(var(--spacing-xxxs) * -1);
      }

      td:first-child,
      th:first-child,
      td:last-child,
      th:last-child {
        position: relative;
      }

      &:hover td:first-child::before,
      th:first-child::before,
      &:hover td:last-child::before,
      th:last-child::before {
        transform: scaleX(1);
      }

      td:first-child::before,
      th:first-child::before,
      td:last-child::before,
      th:last-child::before {
        position: absolute;
        top: calc(var(--spacing-xxxs) * -1);
        bottom: calc(var(--spacing-xxxs) * -1);
        left: calc(var(--spacing-xxxs) * -1);
        width: var(--spacing-xxs);
        background: var(--color-primary-500);
        content: '';
        transition: transform 0.25s;
        transform: scaleX(0);
        transform-origin: 0 0;
      }

      td:last-child::before,
      th:last-child::before {
        right: calc(var(--spacing-xxxs) * -1);
        width: var(--spacing-xxs);
        left: unset;
      }
    }
  }

  &-td {
    padding: var(--spacing-sm) var(--spacing-lg);
    max-width: 40rem;
    min-width: 10rem;
    border: var(--spacing-xxxs) solid var(--color-gray-100);

    &:has(.cmp-button__icon) {
      vertical-align: middle;
    }

    .cmp-filterable-list__list-link {
      text-decoration: underline;

      &:has(.cmp-button__icon) {
        text-decoration: none;
        display: flex;
        justify-content: center;
      }
    }
  }

  /* Sortable Tables */
  &--sortable {
    position: absolute;
    right: var(--spacing-xxxs);
    top: 50%;
    transform: translateY(-50%);

    &-icon {
      display: flex;
      flex-flow: column nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin: 0 0 0 var(--spacing-xs);
      fill: var(--color-gray-300);
      padding: 0.625rem 0.4375rem 0;
      cursor: pointer;
      width: 1.6875rem;
      height: 1.125rem;

      &:hover {
        fill: var(--color-primary-500);
      }

      &-down {
        padding-top: 0;
        margin-top: calc(-1 * var(--spacing-xxs));
      }

      &-active {
        fill: var(--color-black);
      }
    }
  }

  &.cmp-filterable-list__customer-success-stories :not(thead) .cmp-filterable-list__table-row:hover {
    cursor: pointer;
    outline: var(--color-primary-500) solid var(--spacing-xxxs);
  }

  &.cmp-filterable-list__products :not(thead) .cmp-filterable-list__table-row:hover {
    background: var(--color-gray-50);
    cursor: pointer;
  }
}

.cmp-filterable-list__table-td .cmp-abbreviation {
  text-decoration: none;
}
