@import '@react/common.scss';

.cmp-filterable-list__jobs {
  @include regular-md();

  margin-bottom: var(--spacing-lg);

  .cmp-filterable-list__table-tbody .cmp-filterable-list__table-row {
    border-top: var(--spacing-xxxs) solid var(--color-gray-100);

    .cmp-filterable-list__table-td::before {
      content: none;
    }
  }

  .cmp-filterable-list__table-th {
    @include regular-xs();

    padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) 0;
    width: 17%;
    text-transform: uppercase;
    background: none;
    border-right: none;

    &:first-child {
      width: 32%;
      border-left: none;
    }
  }

  .cmp-filterable-list__table-td {
    padding: var(--spacing-md) var(--spacing-md) var(--spacing-md) 0;
    width: 17%;
    border: none;
    max-width: unset;
    min-width: unset;

    &:first-child {
      width: 32%;
    }

    &:nth-child(2) {
      white-space: pre;
    }

    .cmp-filterable-list__list-link {
      text-decoration: none;
    }

    a {
      text-wrap: auto;
    }
  }

  .cmp-filterable-list__table-th:last-child,
  .cmp-filterable-list__table-td:last-child {
    padding-right: 0;
  }
}

@media #{$mq-mobile} {
  .cmp-filterable-list__jobs {
    .cmp-filterable-list__table-row {
      display: block;
      padding: var(--spacing-md) 0;
    }

    .cmp-filterable-list__table-td {
      display: block;
      width: 100%;
      padding: var(--spacing-xxs) 0;

      &:first-child {
        width: 100%;
      }
    }
  }
}
