@import '@site/styles/foundations/media_queries.scss';

.cmp-filterable-list__section-header {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: var(--spacing-xxl) 0 0;
  width: 100%;

  @media #{$mq-desktop} {
    width: 50%;
  }
}
