@import '@react/common.scss';

.cmp-filterable-list__card {
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  background-color: var(--color-white);
  min-width: 0;

  &:hover {
    box-shadow: var(--box-shadow-md);
  }

  &-link {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &-tag {
    @include regular-md;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background: var(--color-gray-700);
    color: var(--color-white);
    padding: var(--spacing-xxs) var(--spacing-sm);
  }

  &-image {
    position: relative;
    aspect-ratio: 16/9;
    height: fit-content;
  }

  &-image--no-object-fit img {
    object-fit: none;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    padding: var(--spacing-lg);

    .cmp-filterable-list__card-description {
      margin-bottom: var(--spacing-sm);
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    max-height: var(--spacing-4xl);
    overflow: hidden;
    margin-bottom: var(--spacing-sm);
  }

  &-title {
    @include bold-lg;

    color: var(--color-gray-900);
    margin: 0 0 var(--spacing-md);
  }

  &-description {
    @include regular-lg;

    color: var(--color-gray-900);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &-read-more {
    @include bold-lg;

    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    color: var(--color-primary-500);
    margin-top: auto;

    .icon-keyboard_arrow_right {
      @include transition-transform;
    }

    &:hover {
      color: var(--color-primary-700);

      .cmp-filterable-list__card-read-more--text {
        text-decoration: underline;
      }

      .icon-keyboard_arrow_right {
        @include link-icon-right;
      }
    }

    &:active {
      color: var(--color-primary-800);

      .cmp-filterable-list__card-read-more--text {
        text-decoration: underline;
      }
    }
  }

  &-meta {
    @include regular-xs;

    color: var(--color-gray-900);
    margin-bottom: var(--spacing-xs);
    text-transform: uppercase;
    max-height: var(--line-size-xs);

    span:not(:last-child)::after {
      content: '|';
      display: inline-block;
      padding: 0 var(--spacing-xs);
    }
  }

  &--aspect-1-1 {
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 100%;
    }
  }
}
