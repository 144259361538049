@import '@react/common.scss';

.cmp-subfilter {
  cursor: pointer;
  padding: var(--spacing-md) 0;
  display: inline-flex;
  flex-direction: column;
  width: toRem(140px);
}

.cmp-subfilter__label {
  @include regular-md;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--border-xs) solid var(--color-gray-700);
  padding: var(--spacing-xs);
  background-color: var(--color-white);
  height: var(--spacing-xxl);

  &::after {
    font-family: var(--icon-font);
    content: var(--icon-keyboard_arrow_down);
    font-size: var(--font-size-icon-md);
    line-height: initial;
    margin-left: var(--spacing-xxs);
  }

  &[aria-expanded='true']::after {
    content: var(--icon-keyboard_arrow_up);
  }
}

.cmp-subfilter__items-wrapper {
  position: relative;
}

.cmp-subfilter__items {
  position: absolute;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-xs);
  width: 100%;

  @media #{$mq-desktop} {
    @include regular-md;

    padding: var(--spacing-xs) 0;
  }

  @media #{$mq-mobile} {
    @include regular-sm;
  }
}

.cmp-subfilter__item {
  @include regular-md;

  padding: toRem(6px) var(--spacing-md);
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: var(--color-gray-100);
  }

  &--is-active {
    background-color: var(--color-gray-50);
  }
}
