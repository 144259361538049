@import '@site/styles/foundations/media_queries.scss';

.cmp-filterable-list__card--testimonial-type {
  box-shadow: none;
  background-color: var(--color-gray-50);
  margin: var(--spacing-4xl) 0 var(--spacing-lg);

  @media #{$mq-mobile} {
    margin: var(--spacing-xxxl) 0 var(--spacing-lg);
  }

  .cmp-filterable-list__card {
    &-link {
      align-items: center;
    }

    &-image {
      position: absolute;
      top: calc(-1 * var(--spacing-4xl));
      left: 50%;
      transform: translateX(-50%);
      height: var(--testimonial-image-height);
      width: var(--testimonial-image-width);
      border-radius: 50%;
      border: var(--spacing-xs) solid var(--color-white);
      overflow: hidden;
      background-color: var(--color-white);
    }

    &-content {
      display: flex;
      align-items: center;
      text-align: center;
      padding: var(--spacing-md) var(--spacing-lg) var(--spacing-lg);
    }

    &-title {
      padding-top: calc(11rem - var(--spacing-4xl));
    }
  }
}
