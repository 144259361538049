@import '@react/common.scss';

.cmp-filterbar__button {
  width: inherit;

  &--select {
    text-transform: capitalize;

    @include button-primary();
  }

  &--reset {
    display: flex;
    align-items: center;
    color: var(--color-primary-500);
    text-transform: capitalize;

    @include bold-lg();
  }

  &--clear-all {
    color: var(--color-primary-500);
    text-decoration: underline;
    align-self: center;

    @include regular-sm();

    @media #{$mq-mobile} {
      align-self: start;
    }
  }

  &--tag {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    border: var(--border-xs) solid var(--color-gray-300);
    padding: var(--spacing-xxs) var(--spacing-xs);

    @include regular-sm();

    &:hover {
      .cmp-filterbar__button-text {
        text-decoration: underline;
      }

      .icon-close {
        color: var(--color-primary-700);
      }
    }

    &:focus {
      transform: scale(0.9);
    }

    .icon-close {
      color: var(--color-primary-500);
      display: flex;
      margin-left: var(--spacing-xs);

      @include regular-md();

      &::before {
        margin-right: 0;
      }
    }
  }

  .icon-reset {
    font-size: var(--font-size-icon-md);
  }
}

.icon-placement {
  &--before-label {
    order: 1;

    ~ .cmp-filterbar__button-text {
      order: 2;
    }
  }

  &--after-label {
    order: 2;

    ~ .cmp-filterbar__button-text {
      order: 1;
    }
  }
}
