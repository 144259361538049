@import '@react/common.scss';

.cmp-filter__title {
  border-bottom: var(--border-l) solid var(--color-primary-500);
  display: flex;
  padding-bottom: var(--spacing-sm);

  &-heading {
    @include bold-lg;

    display: flex;
    color: var(--color-gray-900);
    margin: 0;
  }
}
