@import '@site/styles/foundations/typography.scss';
@import '@site/styles/foundations/media_queries.scss';

.cmp-filterable-list__events-type--list {
  position: relative;

  .cmp-filterable-list__card-meta {
    text-transform: none;
  }

  .cmp-filterable-list__card-tag {
    @include regular-lg;
  }

  @media #{$mq-mobile} {
    .cmp-filterable-list__card-link:has(.cmp-filterable-list__card-tag) .cmp-filterable-list__card-meta {
      display: flex;
      flex-direction: column;
    }
  }
}
