@import '@site/styles/foundations/typography.scss';
@import '@site/styles/foundations/media_queries.scss';

.cmp-filterable-list__events-type--simple {
  .cmp-filterable-list {
    &__heading {
      border-bottom: var(--border-l) solid var(--color-primary-500);
      display: flex;
      justify-content: space-between;
      padding-bottom: var(--spacing-sm);

      &-title {
        @include bold-lg;

        display: flex;
        color: var(--color-gray-900);
        margin: 0;

        .icon {
          font-size: var(--font-size-icon-sm);

          &::before {
            margin-right: var(--spacing-xs);
          }
        }
      }
    }

    &__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--spacing-md) var(--spacing-lg);

      @media #{$mq-1} {
        grid-template-columns: repeat(1, 1fr);
      }

      .cmp-filterable-list__card--list-type,
      .cmp-filterable-list__card--list-type:hover {
        box-shadow: none;
      }

      .cmp-filterable-list__card-meta {
        text-transform: none;
      }
    }

    &__buttons-wrapper {
      justify-content: left;
    }
  }
}
