@import '@react/common.scss';

.cmp-filterbar__filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--spacing-lg);
  margin-bottom: var(--spacing-md);
}

.cmp-filterbar__quickfilter .cmp-filterbar__filters {
  margin-bottom: 0;
  padding: var(--spacing-sm);
  background-color: var(--color-gray-50);

  .cmp-filterbar__checkbox-input::before {
    background-color: var(--color-white);
  }
}

.cmp-filterbar__filter:has(.cmp-filterbar__dropdown) {
  padding-bottom: var(--spacing-xxl);
}

@media #{$mq-desktop} {
  .cmp-filterbar__filter {
    width: 25%;
    margin-bottom: var(--spacing-lg);
  }

  .cmp-filterbar__filter-text {
    width: 100%;
  }
}

@media #{$mq-mobile} {
  .cmp-filterbar__filters {
    display: flex;
    flex-wrap: nowrap;
    gap: 0;
    flex-direction: column;
  }

  .cmp-filterbar__filter {
    width: calc(100vw - var(--spacing-xxxl));
    margin-bottom: var(--spacing-lg);
  }

  .cmp-filterbar__filters--is-open .cmp-filterbar__filter {
    display: none;
  }

  .cmp-filterbar__filters--is-open .cmp-filterbar__filter:has(.cmp-filterbar__dropdown--is-open) {
    display: flex;
  }

  .cmp-filterbar__wrapper--is-open:has(.cmp-filterbar__filters--is-open) .cmp-filterbar__secondary-filters-toggle {
    display: none;
  }
}
