@import '@react/common.scss';

.cmp-sortbar__sort-order-label {
  display: flex;
  align-items: center;

  @media #{$mq-desktop} {
    @include regular-md;
  }

  @media #{$mq-mobile} {
    @include regular-sm;
  }
}
