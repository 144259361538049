@import '@react/common.scss';

.cmp-filterable-list__related-jobs-wrapper {
  padding: var(--spacing-xxxl) 0;

  .cmp-filterable-list__related-jobs-title {
    @include regular-4xl;

    text-align: center;
    margin-bottom: var(--spacing-xl);

    @media #{$mq-mobile} {
      @include regular-3xl;
    }
  }

  .cmp-filterable-list__related-jobs {
    margin-left: auto;
    margin-right: auto;

    @media #{$mq-3} {
      max-width: grid-spacing(3, 12, 11);
    }

    @media #{$mq-4} {
      max-width: grid-spacing(4, 12, 11);
    }

    @media #{$mq-5} {
      max-width: grid-spacing(5, 12, 11);
    }

    @media #{$mq-6} {
      max-width: grid-spacing(6, 12, 11);
    }

    .cmp-filterable-list__card--list-type {
      margin: 0;

      .cmp-filterable-list__card-link {
        padding: var(--spacing-sm) var(--spacing-lg) var(--spacing-lg);
      }

      .cmp-filterable-list__card-title-wrapper {
        @include bold-lg;

        display: flex;
        color: var(--color-primary-500);
      }

      .cmp-button__icon {
        @include transition-transform;

        margin: 0 var(--spacing-xs) 0 0;
      }

      &:hover {
        .cmp-filterable-list__card-title-wrapper {
          color: var(--color-primary-700);
        }

        .cmp-filterable-list__card-title-wrapper--title {
          text-decoration: underline;
        }

        .cmp-button__icon {
          @include link-icon-hover;

          text-decoration: none;
        }
      }

      &:active {
        .cmp-filterable-list__card-title-wrapper {
          color: var(--color-primary-800);
        }
      }
    }

    &--button {
      display: flex;
      justify-content: center;
      margin-top: var(--spacing-lg);
    }
  }
}
