@import '@react/common.scss';

.cmp-filterbar {
  @media #{$mq-desktop} {
    position: relative;
  }

  &__button--is-open-filterbar {
    display: none;
  }

  &__loading-spinner {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 30%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: toRem(60px);
    height: toRem(60px);

    svg {
      fill: var(--color-primary-500);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  @media #{$mq-mobile} {
    &__body {
      padding: var(--spacing-xl) var(--spacing-lg) var(--spacing-4xl);
    }

    &__button--is-open-filterbar {
      @include regular-md;

      padding: var(--spacing-sm);
      border: var(--border-xs) solid var(--color-gray-700);
      width: 100%;
      position: relative;
      text-align: left;
      display: flex;

      &::after {
        @include regular-3xl;

        font-family: var(--icon-font);
        content: var(--icon-filter);
        right: var(--spacing-sm);
        top: 0;
        pointer-events: none;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    &__wrapper:not(.cmp-filterbar__wrapper--is-open) {
      visibility: hidden;
      padding: 0;
      margin: 0;

      .cmp-filterbar__filters,
      .cmp-filterbar__header {
        height: 0;
        margin: 0;
        padding: 0;
      }

      .cmp-filterbar__body {
        height: auto;
        padding: var(--spacing-md) var(--spacing-lg) 0;
        margin: 0 calc(var(--spacing-lg) * -1);
      }

      .cmp-filterbar__active-filters {
        visibility: visible;
      }
    }

    &__wrapper--is-open {
      visibility: visible;
      display: flex;
      flex-direction: column;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: var(--color-gray-50);
      width: 100vw;
      height: 100vh;

      .cmp-filterbar__active-filters {
        margin-bottom: var(--spacing-lg);

        .cmp-filterbar__count {
          display: none;
        }
      }
    }

    .cmp-filterbar__wrapper--is-open:has(.cmp-filterbar__dropdown--is-open) {
      .cmp-filterbar__active-filters {
        display: none;
      }
    }
  }
}

body:has(.cmp-filterbar__wrapper--is-open) .cmp-button--sticky,
body:has(.cmp-filterbar__wrapper--is-open) .cmp-anchor-navigation--sticky {
  z-index: unset;
}

@media #{$mq-mobile} {
  .cmp-filterbar:has(.cmp-filterbar__wrapper--is-open) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 4;
  }

  .cmp-filterbar:not(:has(.cmp-filterbar__wrapper--is-open)) {
    .cmp-filterbar__footer {
      display: none;
    }
  }

  body:has(.cmp-filterbar__wrapper--is-open) {
    .cmp-header,
    .cmp-footercmp,
    .cmp-sortbar {
      display: none;
    }
  }

  .no-scroll {
    overflow: hidden;
  }
}
