@import '@site/styles/settings/functions.scss';
@import '@site/styles/foundations/media_queries.scss';

.cmp-filterable-list__list-events.cmp-filterable-list__events-type--carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  background: var(--color-gray-50);
  margin-bottom: var(--spacing-lg);

  &::before {
    position: absolute;
    z-index: 1;
  }

  .cmp-carousel {
    &__pagination {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
      max-width: toRem(612px);
      width: 100%;
      margin: var(--spacing-lg) auto 0 auto;
      padding: 0 toRem(38px);
    }

    &__pagination-item {
      display: inline-block;
      line-height: 1;
      list-style-type: none;
      margin: 0;
      pointer-events: auto;
      cursor: pointer;
    }

    &__pagination-item--button {
      margin: 0;
      width: 100%;
      height: toRem(3px);
      background: var(--color-gray-300);

      &.is-paginationactive {
        background: var(--color-primary-500);
      }
    }
  }

  .splide__list .splide__slide {
    min-width: 45ch;
    max-width: 58.5ch;

    @media #{$mq-1} {
      min-width: 40ch;
    }
  }

  .splide__arrows {
    padding-bottom: var(--spacing-xl);
  }
}

.carousel.entityList {
  margin: auto;

  .cmp-filterable-list__list-events--carousel-wrapper {
    margin: 0 var(--grid-anti-margin);

    .list-wrapper {
      overflow: hidden;
      display: flex;
      justify-content: center;

      .cmp-filterable-list {
        display: flex;
        justify-content: center;
        overflow: hidden;
      }
    }
  }
}
