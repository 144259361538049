@import '@react/common.scss';

.cmp-carousel__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: baseline;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  width: 100%;
  width: stretch;

  @media #{$mq-1} {
    flex-direction: column;
    align-items: center;
  }

  &::before {
    content: '';
    flex: 0 0 25%;
  }

  &--title {
    flex: 0 0 50%;
    text-align: center;
    margin: 0;
  }

  &--allevents-link {
    flex: 0 0 25%;
    align-items: center;
    justify-content: flex-end;

    @media #{$mq-1} {
      align-self: flex-end;
    }

    &:hover {
      color: var(--color-primary-700);
    }

    &:active {
      color: var(--color-primary-800);
    }
  }
}
