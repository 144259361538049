@import '@site/styles/foundations/typography.scss';
@import '@site/styles/settings/mixins.scss';

.cmp-filterable-list .cmp-button {
  cursor: pointer;
  display: flex;

  &--primary-positive {
    @include button-primary;
  }

  &--secondary-positive {
    @include button-secondary;
  }

  &--primary-positive,
  &--secondary-positive {
    display: flex;
  }

  &__icon {
    @include transition-transform;

    margin-top: 0;
  }

  &:hover {
    .icon-keyboard_arrow_right {
      @include link-icon-right;
    }

    .icon-keyboard_arrow_down {
      @include link-icon-down;
    }
  }

  &.cmp-button--is-loading {
    background-color: transparent;
    position: relative;
    color: transparent;
    border-color: transparent;
    outline: none;
    user-select: none;
    user-select: none;
    box-shadow: none;
    cursor: initial;
    pointer-events: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      top: 0;
      left: calc(50% - 1.25rem);
      border: 0.25rem solid transparent;
      border-left-color: var(--color-gray-700);
      animation-name: spin;
      animation-duration: 555ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}
