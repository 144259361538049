@import '@react/common.scss';

.cmp-filterbar__search {
  &-input-wrapper {
    position: relative;

    @media #{$mq-desktop} {
      width: calc(50% - var(--spacing-sm));
    }
  }

  &-input {
    @include regular-md();

    width: 100%;
    height: var(--spacing-xxl);
    padding: var(--spacing-sm) var(--spacing-xs);
    border: 1px solid var(--color-gray-700);
  }

  &-button {
    position: absolute;
    top: 0;
    right: var(--spacing-xs);
    height: 100%;
    display: flex;
    align-items: center;

    &::after {
      @include regular-3xl();

      font-family: var(--icon-font);
      content: var(--icon-search);
      color: var(--color-gray-900);
      cursor: pointer;
    }
  }

  &-input-wrapper--is-disabled {
    border: var(--border-xs) solid var(--color-gray-300);

    .cmp-filterbar__search-input {
      border: var(--border-xs) solid var(--color-gray-300);
      background-color: var(--color-gray-50);
    }

    .cmp-filterbar__search-button::after, .cmp-filterbar__search-input::placeholder {
      color: var(--color-gray-300);
    }
  }
}
