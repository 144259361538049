@import '@react/common.scss';

.cmp-filterable-list__events-type--carousel {
  .is-active.is-visible .cmp-filterable-list__card {
    height: toRem(282px);

    @media #{$mq-1} {
      height: toRem(402px);
    }
  }

  .cmp-filterable-list__card {
    display: flex;
    height: toRem(254px);
    max-height: toRem(282px);

    @media #{$mq-1} {
      height: toRem(402px);
      max-height: toRem(402px);
    }

    &-link {
      flex-wrap: nowrap;
      width: 100%;
      flex-direction: row;

      @media #{$mq-1} {
        flex-direction: column;
      }
    }

    &-image {
      aspect-ratio: 1/1;
      height: 100%;
      width: 100%;
    }

    &-tag {
      @include regular-sm;

      @media #{$mq-1} {
        top: 40%;
      }
    }

    &-meta {
      @include regular-sm;

      text-transform: none;
      padding: var(--spacing-xs) var(--spacing-xs) 0 0;
    }

    &-title {
      margin-bottom: var(--spacing-sm);
    }

    &-content {
      width: 50%;
      flex: 1;

      @media #{$mq-1} {
        width: 100%;
      }
    }

    &-event-location {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--city {
        @include bold-2xl;

        margin-bottom: var(--spacing-xxs);
      }

      &--country {
        @include bold-lg;
      }
    }

    .cmp-filterable-list__card-image-wrapper {
      background-color: var(--color-gray-50);
      width: 50%;
      position: relative;

      @media #{$mq-1} {
        width: 100%;
        height: 40%;
      }

      .cmp-filterable-list__card-image {
        &--decoration {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: var(--spacing-lg);
        }
      }

      &:has(.cmp-filterable-list__card-image) .cmp-filterable-list__card-image--decoration {
        background: linear-gradient(to top, var(--color-black-transparent), rgb(0 0 0 / 0%));
        color: var(--color-white);
      }
    }
  }

  .splide__list {
    align-items: center;
    height: toRem(282px);

    @media #{$mq-1} {
      height: toRem(402px);
    }
  }
}
